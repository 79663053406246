import React from "react";
import styled, { css } from "styled-components";
import Layout from "../components/Layout";

import aargauImageUrl from "../images/Aargau.jpg";
import zugImageUrl from "../images/carousel/Zug.jpg";
import lakeImageUrl from "../images/carousel/SeeCh.jpg";

const Box = styled.div`
  padding: 1.45rem 1.45rem 1.45rem 1.45rem;
  width: 50%;
  text-align: center;
  margin: auto;
`;
const Toolbar = styled.div`
  margin-bottom: 5vh;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color-primary-border);
`;
const Text = styled.p`
  padding: 10px 10px 10px 10px;
  line-height: 150%;
`;
const Image = styled.div<{ src?: string; }>`
  width: 50%;
  float: left;
  height: auto;
  background-size: cover;
  background-position: center center;
  ${(props) => css`background-image: url(${props.src});`}
`;

export default () => (
  <Layout>
    <Toolbar>
      <Image src={aargauImageUrl} />
      <Box>
        <h1>Cornelia Stifani</h1>
        <h4>CEO und Geschäftsführerin</h4>
        <Text>Unser Ziel ist Ihre Bedürfnisse zu erkennen und Ihnen eine unabhängige und massgeschneiderte Lösung anzubieten.</Text>
        <Text>Wir setzen alles daran, dass Sie, lieber Kunde, unser Angebot nicht abschlagen können.</Text>
      </Box>
    </Toolbar>
    <Toolbar>
      <Box>
        <h3>Unsere Zielgruppe</h3>
        <Text>Unabhängig, ob Sie Privat, eine Einzelfirma oder eine juristische Person sind, wir möchten Sie als begeisterte Kunden dabei haben.</Text>
      </Box>
      <Image src={zugImageUrl} />
    </Toolbar>
    <Toolbar>
      <Image src={lakeImageUrl} />
      <Box>
        <h3>Unser Angebot</h3>
        <Text><b>Neutralität, Kompetenz und Effektivität!</b></Text>
        <Text>Die langjährige Erfahrung in der Finanzbranche ermöglicht neutrale und massgeschneiderte Lösungen. </Text>
      </Box>
    </Toolbar>
  </Layout>
);
